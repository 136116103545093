import React from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import useDevice from "./hooks/useDevice";
const PostCard = (props) => {
  const { img, title, content, url } = props.post;

  return (
    <Card
      onClick={() => (window.location.href = url)}
      sx={{ width: useDevice() <= 720 ? 300 : 345, marginBottom: "10px" }}
    >
      <CardHeader subheader={title} sx={{ fontWeight: "bold" }} />
      <CardMedia
        image={img}
        style={{ height: 0, paddingTop: "10%", paddingBottom: "50%" }}
      />
      <CardContent>
        <Typography variant="span">{content}</Typography>
      </CardContent>
    </Card>
  );
};

export default PostCard;
