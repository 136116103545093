import React, { Component } from "react";
import { Container, Box, Typography } from "@mui/material";
import { ReactComponent as Research } from "./images/research.svg";
import { ReactComponent as Verify } from "./images/verify.svg";
import { ReactComponent as Educate } from "./images/educate.svg";
import CarouselHow from "./carouselHow";
import SafetyWarning from "@mui/icons-material/SystemSecurityUpdateWarning";

const HowItWorks = () => {
  const [activeSlideIndex, setActiveSlideIndex] = React.useState();
  return (
    <Container
      sx={{
        marginTop: "10px",
        padding: "20px",
        height: { sx: "", sm: "100vh" },
      }}
    >
      <div style={{ borderBottom: "1px dashed #db0000", marginBottom: "20px" }}>
        <Typography
          variant="h3"
          color="primary"
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "60px" },
          }}
        >
          HOW OIS WORKS
        </Typography>
      </div>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          width: "100%",
          padding: "20px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", md: "30%" },
          }}
        >
          <Research />
          <Typography variant="h4" color="primary">
            RESEARCH
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Via local communities we gather data and research latest online
            safety threat
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "100%", md: "30%" },
          }}
        >
          <Verify />
          <Typography variant="h4" color="primary">
            VERIFY
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Drawing strength from volunteer experts, our team verify the data
            and results from research
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "100%", md: "30%" },
          }}
        >
          <Educate />
          <Typography variant="h4" color="primary">
            EDUCATE
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Together with you we spread real safety info in time
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "center",
        }}
      >
        <CarouselHow />
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          color="primary"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <SafetyWarning />
          Our First Commandment of Online Safety
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Never share private/sensitive personal data with anyone, not even from
          OIS in livechat, emails or via phone calls.
        </Typography>
      </Box>
    </Container>
  );
};

export default HowItWorks;
