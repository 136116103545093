import React, { Component, useState } from "react";
import {
  Box,
  ListItem,
  List,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import "../App.css";

const SideDrawer = (props) => {
  //   const [show, close] = useState(true);
  const scrollTo = (id) => {
    const to = document.getElementById(id);
    to.scrollIntoView({ behavior: "smooth" });
  };
  const toggleDrawer = (value) => {
    props.close(value);
  };
  return (
    <SwipeableDrawer
      anchor={"left"}
      open={props.show}
      onClick={() => toggleDrawer(false)}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Box
        role="presentation"
        onClick={() => toggleDrawer(false)}
        sx={{
          bgcolor: "#db0000",
          fontWeight: "bold",
          border: "1px solid #db0000",
          height: "100%",
          color: "white",
        }}
      >
        <List>
          <ListItem
            button
            key={1}
            onClick={() => window.location.assign("#howitworks")}
          >
            <ListItemText primary={"HOW IT WORKS"} />
          </ListItem>
          <ListItem
            button
            key={1}
            onClick={() => window.location.assign("#blog")}
          >
            <ListItemText primary={"BLOG POSTS"} />
          </ListItem>
          <ListItem
            button
            key={2}
            onClick={() =>
              (window.location.href = "https://forms.gle/mNdhn8Xng4ZPGL336")
            }
          >
            <ListItemText primary={"VOLUNTEER"} />
          </ListItem>
          {/* <ListItem button key={3}>
            <ListItemText primary={"ABOUT"} />
          </ListItem>
          <ListItem
            button
            key={4}
            onClick={() => window.location.assign("#contact")}
          >
            <ListItemText primary={"CONTACT"} />
          </ListItem> */}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default SideDrawer;
