import React from "react";
import {
  Container,
  Stack,
  TextField,
  IconButton,
  Button,
  Box,
  Typography,
  Link,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Room,
  Mail,
} from "@mui/icons-material";

const Footer = () => {
  const scrollTo = (id) => {
    const to = document.getElementById(id);
    to.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Box sx={{ bgcolor: "#db0000", minHeight: "200px" }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            height: "100%",
            alignItems: "flex-start",
            padding: "10px",
          }}
        >
          <Stack sx={{ width: { xs: "100%", md: "33%" } }}>
            <Typography variant="h5" sx={{ color: "white" }}>
              We Are Social
            </Typography>
            <Stack direction="row" sx={{ marginLeft: "-10px" }}>
              <Link href="https://facebook.com/onlineinfosafe">
                <IconButton>
                  <Facebook sx={{ color: "white" }} />
                </IconButton>
              </Link>
              <Link href="https://twitter.com/onlineinfosafe">
                <IconButton>
                  <Twitter sx={{ color: "white" }} />
                </IconButton>
              </Link>
              <Link>
                <IconButton href="https://safeonline.com.ng/#">
                  <Instagram sx={{ color: "white" }} />
                </IconButton>
              </Link>
            </Stack>
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "33%" } }}>
            <Typography variant="h5" sx={{ color: "white" }}>
              Useful Link
            </Typography>
            <Stack>
              <Typography
                variant="span"
                onClick={() => scrollTo("howitworks")}
                sx={{ color: "white", cursor: "pointer" }}
              >
                How It Works
              </Typography>
              <Link href="https://forms.gle/mNdhn8Xng4ZPGL336">
                <Typography variant="span" sx={{ color: "white" }}>
                  Volunteer
                </Typography>
              </Link>
              <Typography
                variant="span"
                onClick={() => scrollTo("blog")}
                sx={{ color: "white", cursor: "pointer" }}
              >
                Blog Posts
              </Typography>
              <Link href="https://safeonline.com.ng/blog">
                <Typography
                  variant="span"
                  sx={{ color: "white", cursor: "pointer" }}
                >
                  Our Blog
                </Typography>
              </Link>
            </Stack>
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "33%" } }}>
            <Typography variant="h5" sx={{ color: "white" }}>
              Contact Info
            </Typography>
            <Stack direction="row" sx={{ marginLeft: "-10px" }}>
              <IconButton>
                <Room sx={{ color: "white" }} />
              </IconButton>
              <Typography sx={{ color: "white" }}>
                11 Kashim Ibrahim Road, Old GRA, Makurdi, Benue State.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ alignItems: "center", marginLeft: "-10px" }}
            >
              <IconButton>
                <Mail sx={{ color: "white" }} />
              </IconButton>
              <a href="mail:info@buildbrothers.com">
                <Typography sx={{ color: "white" }}>
                  info@buildbrothers.com
                </Typography>
              </a>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box sx={{ bgcolor: "white", height: "40px" }}>
        <Container
          sx={{ height: "100%", alignItems: "center", display: "flex" }}
        >
          <Typography variant="span">
            © 2020 Build Brothers NG. All rights reserved
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
