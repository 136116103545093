import React, { Component } from "react";
import char from "./images/char.svg";
import HideAppBar from "./navBar";
import { Container, Typography, Box, Button, Stack } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import char1 from "./images/heyYou.svg";

const Btn = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#db0000", 1),
  "&:hover": {
    backgroundColor: alpha("#db0000", 0.9),
  },
}));

const Landing = (props) => {
  const scrollTo = (id) => {
    const to = document.getElementById(id);
    to.scrollIntoView({ behavior: "smooth" });
  };
  const actionButtons = (
    <Stack direction="row" spacing={3}>
      <Button variant="contained" onClick={() => scrollTo("howitworks")}>
        HOW IT WORKS
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          (window.location.href = "https://forms.gle/mNdhn8Xng4ZPGL336")
        }
      >
        VOLUNTEER
      </Button>
    </Stack>
  );

  return (
    <div className="mainContainer">
      <HideAppBar />
      <Container className="hero-side" sx={{}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            height: "100%",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", sm: "50%" },
              height: { sm: "100%", xs: "45%" },
              justifyContent: { xs: "flex-start", sm: "flex-end" },
              alignItems: "center",
            }}
          >
            <Stack>
              <Typography
                variant="span"
                color="primary"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "2rem", md: "2.3rem" },
                }}
              >
                HEY THERE! YES, YOU!!!
              </Typography>
              <Typography
                variant="span"
                color="primary"
                sx={{
                  textAlign: "center",
                  fontSize: { xs: "1.3rem", md: "1.5rem", color: "#21262c" },
                }}
              >
                Let's make the internet a safer place for everyone.
                <br /> Keep scrolling to learn how you can help. Everyone is
                welcomed.
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: { xs: "none", sm: "flex" },
                height: "40%",
                justifyContent: "center",
              }}
            >
              {actionButtons}
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: { xs: "100%", md: "50%" },
              height: { md: "100%", xs: "55%" },
              justifyContent: "center",
            }}
          >
            <Box component="img" src={char1} sx={{ width: "100%" }} />
            <Stack
              sx={{
                display: { xs: "flex", sm: "none" },
                alignItems: "center",
                padding: "5px",
              }}
            >
              {actionButtons}
            </Stack>
          </Stack>
        </Box>
        {/* <Box
          className="heyThere"
          sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
        >
          <Box
            sx={{ width: { xs: "100%", sm: "50%" },  }}
          >
            <Typography
              variant="h3"
              sx={{
                // fontSize: "7vh",
                textAlign: "center",
                color: "#db0000",
                lineSpacing: "0.6pt",
                padding: "0px",
                fontFamily: "'Patrick Hand', cursive",
              }}
            >
              HEY THERE! YES YOU!!!
            </Typography>
            <Typography
              sx={{
                fontSize: "3vh",
                textAlign: "center",
                fontFamily: "'Courgette', cursive",
              }}
            >
              Let's make the internet a safer place for everyone
            </Typography>
            <Typography
              sx={{
                fontSize: "3vh",
                textAlign: "center",
                fontFamily: "'Courgette', cursive",
              }}
            >
              Keep scrolling to learn how you can help.
            </Typography>
            <Typography
              sx={{
                fontSize: "3vh",
                textAlign: "center",
                fontFamily: "'Courgette', cursive",
              }}
            >
              Everyone is welcomed.
            </Typography>
          </Box>
          <Box className="img-sec" sx={{ width: { xs: "100%", sm: "70%" } }}>
            <img src={char1} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: { xs: "20%", sm: "auto" },
            width: { xs: "auto", sm: "50%" },
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-around",
          }}
        >
          <Btn
            onClick={() => window.location.assign("#howitworks")}
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "40%" },
            }}
            disableElevation
          >
            HOW IT WORKS
          </Btn>
          <Button
            onClick={() => window.location.assign("#howitworks")}
            sx={{
              color: "#db0000",
              fontWeight: "bold",
              border: "1px solid #db0000",
              width: { xs: "100%", sm: "40%" },
            }}
            disableElevation
          >
            VOLUNTEER
          </Button>
        </Box> */}
      </Container>
    </div>
  );
};

export default Landing;
