import React, { useState } from "react";

import PropTypes from "prop-types";
import SideDrawer from "./drawer";
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  useScrollTrigger,
  Box,
  Slide,
  IconButton,
  InputBase,
  Stack,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";

import { ReactComponent as MenuI } from "./images/menu.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#db0000", 0.15),
  "&:hover": {
    backgroundColor: alpha("#db0000", 0.25),
  },
  marginLeft: 0,
  width: "70%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "70%",
    [theme.breakpoints.up("md")]: {
      width: "70%",
      // "&:focus": {
      //   width: "12ch",
      // },
    },
    // width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const mainWindow = window;
const HideAppBar = (props) => {
  const [query, setQuery] = useState("");
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  const [show, close] = React.useState(false);

  const closeDrawer = (value) => {
    close(value);
  };

  const scrollTo = (id) => {
    const to = document.getElementById(id);
    to.scrollIntoView({ behavior: "smooth" });
  };

  const SearchQuery = (e) => {
    if (e.keyCode === 13 || e.key == "Enter") {
      mainWindow.location.href = `https://safeonline.com.ng/blog/?s=${query}`;
    }
  };

  const [shadow, setShadow] = React.useState(0);
  React.useEffect(() => {
    if (document.documentElement.scrollTop <= 150) {
      setShadow(0);
    } else {
      setShadow(2);
    }
    console.log(document.documentElement.scrollTop);
  }, [trigger, document.documentElement.scrollTop]);
  return (
    <div>
      <CssBaseline />
      <HideOnScroll {...props}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            sx={{
              boxShadow: shadow,
              paddingLeft: { xs: 0, sm: 10 },
              paddingRight: { xs: 0, sm: 10 },
              bgcolor: "white",
            }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                }}
                onClick={() => closeDrawer(true)}
              >
                <MenuI />
              </IconButton>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    color: "#db0000",
                  }}
                >
                  OIS
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: { xs: "none", lg: "block" },
                    color: "#db0000",
                  }}
                >
                  Online Informed And Safe
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <Button
                  onClick={() => scrollTo("howitworks")}
                  sx={{
                    color: "#db0000",
                    fontWeight: "bold",
                  }}
                >
                  HOW IT WORKS
                </Button>

                <Button
                  onClick={() => scrollTo("blog")}
                  sx={{
                    color: "#db0000",
                    fontWeight: "bold",
                  }}
                >
                  BLOG POSTS
                </Button>

                <Button
                  onClick={() =>
                    (mainWindow.location.href =
                      "https://forms.gle/mNdhn8Xng4ZPGL336")
                  }
                  sx={{
                    color: "#db0000",
                    fontWeight: "bold",
                  }}
                >
                  VOLUNTEER
                </Button>
                {/* <Button
                  sx={{
                    color: "#db0000",
                    fontWeight: "bold",
                  }}
                >
                  ABOUT
                </Button>
                <Button
                  onClick={() => scrollTo("contact")}
                  sx={{
                    color: "#db0000",
                    fontWeight: "bold",
                  }}
                >
                  CONTACT
                </Button> */}
              </Stack>
              <Search edge="end">
                <SearchIconWrapper sx={{ cursor: "pointer" }}>
                  <SearchIcon
                    sx={{
                      color: "#db0000",
                    }}
                  />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    color: "#db0000",
                  }}
                  placeholder="Search IOS"
                  inputProps={{ "ara-label": "search" }}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyPress={SearchQuery}
                />
              </Search>
            </Toolbar>
          </AppBar>
          <Toolbar></Toolbar>
        </Box>
      </HideOnScroll>
      <SideDrawer show={show} close={closeDrawer} />
    </div>
  );
};

export default HideAppBar;
