import React, { useEffect, useState } from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import { Typography, Box, Button } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import PostCard from "./postCard";
import useDevice from "./hooks/useDevice";
const BlogPost = () => {
  const [screen, setScreen] = useState(useDevice());
  const posts = [
    {
      img: "https://safeonline.com.ng/resources/images/opay-banner.png",
      title: "Opay Investment is it legit?.",
      content:
        "Many people feel Opay investment is legit because of the name “Opay”. This is wrong and one should not believe that an investment is legitimate simple because of the name it carries....",
      url: "https://safeonline.com.ng/blog/2020/12/03/opay-investment-is-it-legit/",
    },
    {
      img: "https://i2.wp.com/safeonline.com.ng/blog/wp-content/uploads/2020/12/phishig.jpg",
      title: "What is Phishing: Everything you need to know.",
      content:
        "Phishing is a cybercrime in which a target or targets are contacted by email, telephone or text message by someone posing as a legitimate institution to...",
      url: "https://safeonline.com.ng/blog/2020/12/04/what-is-phishing-everything-you-need-to-know/",
    },
    {
      img: "https://i1.wp.com/safeonline.com.ng/blog/wp-content/uploads/2021/05/https.png",
      title: "Is HTTPS Really Secure: What SSL/HTTPS is And is Not",
      content:
        "Hi there! Today we will be learning about HTTPS which is short for HyperText Transfer Protocol Secure. We will learn about what HTTPS is and...",
      url: "https://safeonline.com.ng/blog/2021/05/29/is-https-really-secure-what-ssl-https-is-and-is-not/",
    },
    {
      img: "https://i1.wp.com/safeonline.com.ng/blog/wp-content/uploads/2021/05/childrens-day.jpg",
      title: "Children’s Day 2021: Top 5 Safety Tips for Kids Online",
      content:
        "Happy Children’s Day 2021 from us at Online Info Safe. We are wishing all parents and others that are responsible for the wellbeing of children...",
      url: "https://safeonline.com.ng/blog/2021/05/27/childrens-day-2021-top-5-tips-for-kids-online/",
    },
    {
      img: "https://safeonline.com.ng/blog/wp-content/uploads/2021/01/nin2.jpg",
      title: "Linking your SIM with NIN, Everything you need To know.",
      content:
        "Following the directive of the Nigerian Communications Commission (NCC) asking all Telecommunications companies to disconnect the SIM Cards of...",
      url: "https://safeonline.com.ng/blog/2021/01/16/linking-your-sim-with-nin-everything-you-need-to-know/",
    },
  ];
  useEffect(() => {
    if (document) {
      try {
        const nav = document.getElementsByClassName(
          "styles-module_carousel-arrow__26sRw"
        );
        nav[0].className = "styles-module_carousel-arrow__26sRw left";
        nav[1].className = "styles-module_carousel-arrow__26sRw right";
      } catch (err) {}
    }
  }, []);

  return (
    <Box sx={{ marginTop: "10px", paddingTop: "10px" }}>
      <Typography
        id="blog"
        variant="h4"
        color="primary"
        sx={{ textAlign: "center", margin: "30px" }}
      >
        LATEST FROM OUR BLOG
      </Typography>
      <div
        id="blog"
        style={{
          width: "90%",
          height: "70%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Carousel
          className="sliderPost"
          show={screen < 720 ? 1 : screen < 900 && screen > 720 ? 2 : 3}
          slide={1}
          swiping={true}
          responsive={true}
          infinite
        >
          {posts.map((post, key) => (
            <PostCard key={post.title} post={post} />
          ))}
        </Carousel>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "50px" }}
      >
        <Button
          onClick={() =>
            (window.location.href = "https://safeonline.com.ng/blog/")
          }
          color="primary"
          variant="contained"
          sx={{ margin: "auto" }}
        >
          Visit Our Blog For More Secuirity Content
        </Button>
      </div>
    </Box>
  );
};

export default BlogPost;
