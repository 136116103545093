import React, { Component, useRef } from "react";
import HideAppBar from "./components/navBar";
import Landing from "./components/landing";
import SideDrawer from "./components/drawer";
import "./App.css";
import { Typography } from "@mui/material";
import HowItWorks from "./components/howitworks";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CarouselHow from "./components/carouselHow";
import BlogPost from "./components/blogPost";
import Contact from "./components/contact";
import Footer from "./components/footer";
const myTheme = createTheme({
  palette: {
    primary: {
      main: "#db0000",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={myTheme}>
      <div id="welcome">
        <Landing />
      </div>
      <div id="howitworks">
        <HowItWorks />
      </div>
      <div id="blog" style={{ marginTop: "50px" }}>
        <BlogPost />
      </div>
      {/* <div id="contact" style={{ height: "100vh", marginTop: "50px" }}>
        <Contact />
      </div> */}
      <div id="footer">
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default App;
