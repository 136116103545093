import React, { useState, useEffect } from "react";

const useDevice = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const updateDeviceWidth = () => {
    setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", () => updateDeviceWidth());

    return () =>
      window.removeEventListener("resize", () => updateDeviceWidth());
  }, []);

  return deviceWidth;
};

export default useDevice;
