import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Paper } from "@mui/material";
import Research from "./images/research.svg";
import Verify from "./images/verify.svg";
import Educate from "./images/educate.svg";
import { MobileStepper } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";

const docs = [
  {
    label: "First We Research",
    img: Research,
    text: "Via local communities we gather data and research latest online safety threat",
  },
  {
    label: "Second We Verify",
    img: Verify,
    text: "Drawing strength from volunteer experts, our team verify the data and results from research",
  },
  {
    label: "And Finally We Educate",
    img: Educate,
    text: "Together with you we spread real safety info in time",
  },
];
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const CarouselHow = () => {
  const theme = useTheme();
  const [active, setActive] = useState(0);
  const maxSteps = 3;
  const img = Research;

  const handleActive = (step) => {
    setActive(step);
  };
  return (
    <Box
      sx={{
        maxWidth: 400,
        flexGrow: 1,
        textAlign: "center",
        maxWidth: "100%",
      }}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={active}
        onChangeIndex={handleActive}
        enableMouseEvents
        interval={4000}
      >
        {docs.map((step, index) => (
          <div key={step.label} style={{ textAlign: "center" }}>
            {Math.abs(active - index) <= 2 ? (
              <>
                <Box
                  component="img"
                  sx={{
                    height: 255,
                    display: "block",
                    maxWidth: 400,
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step.img}
                />
                <Typography
                  variant="h4"
                  color="primary"
                  sx={{ textAlign: "center" }}
                >
                  {docs[active].label}
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {docs[active].text}
                </Typography>
              </>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
};

export default CarouselHow;
